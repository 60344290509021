import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Type from "./Type";
import myImg from "../../Assets/poto.jpeg";
import Tilt from "react-parallax-tilt";
import "./Home.css"
import Projects from "../Projects/Projects";
import Aboutcard from "../About/AboutCard";
import Github from "../About/Github";
import Techstack from "../About/Techstack";
import Toolstack from "../About/Toolstack";
import {
  AiFillGithub,
  AiTwotoneMail,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home() {
  return (
    <div>
       <section>
        <Container fluid className="home-section" id="home">
          <Particle />
          <Container className="home-content">
            <Row>
              <Col md={8} className="home-header">
                <h1 style={{ paddingBottom: 15 }} className="heading">
                  Hi There!{" "}
                  <span className="wave" role="img" aria-labelledby="wave">
                   👋🏿
                  </span>
                </h1>
                <h1 className="heading-name">
                  I'M
                  <strong className="main-name"> KEFAH WAMBAI</strong>
                </h1>
                <div style={{ padding: 50, textAlign: "left" }}>
                  <Type />
                </div>
              </Col>
              <Col md={4} className="myAvtar">
                <Tilt>
                  <img src={myImg} className="img-fluid image" alt="avatar" data-aos="fade-left"/>
                </Tilt>
              </Col>
            </Row>        
          </Container>
        </Container>       
      </section>
      <section id="about-section">
          <Container fluid className="about-section">
            <Particle />
          <Container>
            <Row style={{ justifyContent: "center", padding: "10px" }}>
              <Col  
                data-aos="fade-up" 
                data-aos-duration="3000"
                md={7}
                style={{
                  justifyContent: "center",
                  paddingTop: "20px",
                  marginTop: '-5rem',
                  paddingBottom: "50px",
                }}>
                <h1 style={{ textAlign: "center",fontSize: "2.1em", paddingBottom: "20px" }}>
                  About Me
                </h1>
                <Aboutcard  data-aos="fade-up" />
              </Col>      
            </Row>
            <h1 style={{ textAlign: "center",fontSize: "2.1em", paddingBottom: "20px" }} className="project-heading" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
              Professional <strong className="purple">Skillset </strong>
            </h1>
            <Techstack />

            <h1 style={{ textAlign: "center",fontSize: "2.1em", paddingBottom: "20px" }} className="project-heading"  data-aos="flip-right" data-aos-duration="3000">
              <strong className="purple">Tools</strong> I use
            </h1>
            <Toolstack />
            <Github />         
          </Container>
        </Container>    
      </section>
      <section>
        <Container fluid className="project-section">
          <Particle />
          <Projects/>
        </Container>
      </section>
      <section>
      <div>
      <Container fluid className="resume-section">
        <Particle />          
        <Row>
          <Col md={12} className="home-about-social" style={{ marginTop: '0' }}>            
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/kefahwambai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>  
              <li className="social-icons">
                <a
                  href="mailto:kefahngwei@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiTwotoneMail />
                </a>
              </li>             
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/kefah-wambai-b224b0269/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>              
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
      </section>
    </div>
   
  );
}

export default Home;
